  import "./teachers-section.css";
  import { Spinner } from "react-bootstrap";
  import { useTranslation } from "react-i18next";
  import { useDispatch, useSelector } from "react-redux";
  import { Swiper, SwiperSlide } from "swiper/react";
  import { useEffect, useState } from "react";
  import { A11y, Autoplay, Navigation, Pagination } from "swiper/modules";
  import failedImg from "../../../../images/blank-sheet-with-magnifying-glass-icon-3d-rendering-3d-render-cartoon-icon-style-concept_457716-1702-removebg-preview.png"
  import TeacherCard from "./teacher-card/TeacherCard";
  import { Link } from "react-router-dom";
  import { getTeachersFunc } from "../../../../store/slices/teachers/GetTeachersSlicer";
  export default function TeachersSection() {
      const {t} = useTranslation();
      const {loading , data} = useSelector(
          state => state.getTeachersSlicer
      )
      const {data:languageData} = useSelector(
        state => state.getLanguageSettingsSlicer
      )
      const dispatch = useDispatch();
      const [teachresList, setTeachresList] = useState([]);
      const teachersApi = `${process.env.REACT_APP_PUBLIC_API}/api/teachers`;
    
      let params = {
        breakpoints: {
          200: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        },
      };
    
      const getData = () => {
        dispatch(getTeachersFunc(teachersApi))
          .then(result => {
            if(result) {
              setTeachresList(result?.payload)
            }
          });
      };
    
      useEffect(() => {
        getData();
      }, []);
    
      // useEffect(() => {
      //   data  && setTeachresList(data);
      // }, [data]);
      // useEffect(() => console.log(data) , [data])
      return (
          <>
          
          <section className="company-logos-one py-[100px] home-teachers-section bg-[#f9f9f9]">
            {/* <div className="teachers-section-overlay-cover absolute opacity-90 w-full h-full top-0 left-0"></div> */}
          <div className="container">
            <div className="section-title text-center">
              {/* <span className="section-title__tagline text-[#00a4e5]">{t("Talented Instructors")}</span> */}
              <h2 className="section-title__title">{t("Our Expert Instructors")}</h2>
            </div>
            <div className="relative w-full aos" data-aos="fade-up">
              <Swiper
                className="parent-of-slides overflow-hidden w-full py-[20px]"
                // data-aos="fade-up"
                {...params}
                // slidesPerView={4}
                navigation
                modules={[Pagination, Navigation , Autoplay , A11y]}
                autoplay={{ delay: 5000 }}
                // loop = {true}
                pagination={{ clickable: true }}
                spaceBetween={40}
                speed={1000}
              >
                {
                  loading
                    ?
                    <>
                      <div className="row d-flex justify-content-center items-center gap-2">
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                      </div>
                    </>
                    :
                    (
                      teachresList.length >= 1
                        ?
                        (
                          <div className="row  w-full flex justify-center items-center bg-slate-400">
                            {
                            teachresList?.slice(0,7).map((teacher, index) => {
                              return (
                                <SwiperSlide
                                  key={index}
                                  className="col-sm-12 bg-slate-300"
                                  // data-aos="fade-up"
                                >
                                  <TeacherCard
                                  id = {teacher.id}
                                  name={teacher.name}
                                  coursesCount={teacher.courses_count}
                                  teacherImg={teacher.image_url}
                                  baio={teacher.baio}
                                  inSwiper={true}
                                  />
                                </SwiperSlide>
                              );
                            })
                            }
                          </div>
                        )
                        :
                        <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                          <img
                            loading="lazy"
                            src={failedImg}
                            alt="failed img"
                            className="w-[150px] mb-3"
                          />
                          <p className="">
                            {t("No Teachers available yet")}!
                          </p>
                        </div>
                    )
                }
              </Swiper>
            </div>
            <div className="relative flex justify-center items-center group w-fit mx-auto mt-[50px]">
              <Link to="/instructors" className=" relative text-slate-700">{t("Show More")}</Link>
              {
                languageData?.defaultLang?.slug === "ar"
                ?
                <i className="fa-solid fa-arrow-left mx-2  transition-all duration-300 group-hover:translate-x-[-6px]"></i>
                :
                <i className="fa-solid fa-arrow-right mx-2  transition-all duration-300 group-hover:translate-x-[6px]"></i>
              }
            </div>
          </div>
        </section>
          
          </>
      )
  }