import "./details-page.css";
import imgHeader from "../../../../images/details-page/header-img.jpg";
import videoIcon from "../../../../images/video-camera.png";
import quizIcon from "../../../../images/quiz.png";
import fileIcon from "../../../../images/google-docs.png";
import pdfIcon from "../../../../images/file.png";
import rich_textIcon from "../../../../images/files.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import CustomLoaders from "../../../../component/ui/CustomLoaders";
import { getSingleCourseFunc } from "../../../../store/slices/courses/singleCourseSlicer";
import PageHeader from "../../../../component/ui/PageHeader";
import SubscirbeForm from "./SubscribeForm";
import ShowVideoModal from "../../../../component/show-video-modal/ShowVideoModal";
import { postCompletingLessonFunc } from "../../../../store/slices/lessons/PostCompletingLessonSlicer";
import { getSubscriptionsCourses } from "../../../../store/slices/users/subscriptionsCoursesSlicer";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DiscountIcon from '@mui/icons-material/Discount';
import CategoryIcon from '@mui/icons-material/Category';
import Button from '@mui/material/Button';
import dfCourseImg from "../../../../images/df-course-img.webp"
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import QuizIcon from '@mui/icons-material/Quiz';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, ListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '@mui/material/styles';
import ContextApi from "../../../../store/slices/Context";
import { List } from "antd";
import ReviewForm from "./ReviewForm";
import Loading from "../../../../component/loading/Loading";
import { toast } from "react-toastify";
import ActivationCodeModal from "../../../../component/global/global-modals/ActivationCodeModal";
import QrCode2Icon from '@mui/icons-material/QrCode2';
// import CreditCardIcon from '@mui/icons-material/CreditCard';
import ContactlessIcon from '@mui/icons-material/Contactless';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { useTranslation } from "react-i18next";
import LockPersonIcon from '@mui/icons-material/LockPerson';

function Chapters({ chapters }) {
  const { t } = useTranslation();
  const token = window.localStorage.getItem("token");
  const userdata = window.localStorage.getItem("userData");
  const { loading, error, data: executionData } = useSelector(
    (s) => s.singleCourseSlicer
  );
  const navigate = useNavigate();
  const { loading: showNormalLessonLoading, data: normalLessonData } = useSelector(
    (state) => state.showSingleLessonSlicer
  );

  const switchLessonType = (type) => {
    switch (type) {
      case "video":
        return <PlayCircleFilledIcon className="text-primary"/>;
      case "pdf":
        return <PictureAsPdfIcon className="text-primary"/>;
      case "file":
        return <FolderZipIcon className="text-primary"/>;
      case "quiz":
        return <QuizIcon className="text-primary"/>;
      case "rich_text":
        return <AssignmentIcon className="text-primary"/>;
      default:
        return <PictureAsPdfIcon className="text-primary"/>;
    }
  };

  const showFreeLesson = (lesson) => {
    if (token && userdata) {
      if (lesson) {
        navigate(`/lessons/${executionData?.courseExecution?.id}?sub_status=${0}`);
      }
    } else {
      toast.error(t("You must log in to view the free content!"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Box className="p-0 m-0">
        {chapters?.length >= 1 &&
          chapters.map((chapter, index) => (
            <Accordion className="p-0 mb-3 border-none before:bg-transparent shadow-none" key={chapter.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="text-stone-50" />}
                aria-controls={`panel-${chapter.id}-content`}
                id={`panel-${chapter.id}-header`}
                className="bg-primary rounded-lg shadow-none"
              >
                <Typography className="font-bold text-lg text-stone-50">
                  <InsertChartIcon className="mx-2" />
                  {chapter.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {chapter.lessons_details?.length >= 1 ? (
                  chapter.lessons_details.map((lesson, lessonIndex) => (
                    <List key={lessonIndex} onClick={() => lesson?.can_view_without_pay === 1 && showFreeLesson(lesson)}>
                      <ListItem className="cursor-pointer flex justify-between items-center">
                        <Typography className="">
                          {switchLessonType(lesson.type)} {lesson.name}
                        </Typography>
                        {lesson?.can_view_without_pay === 1 ? <>{t("Free Content")}</> : <LockPersonIcon className="text-primary opacity-80" />}
                      </ListItem>
                    </List>
                  ))
                ) : (
                  <h2 className="my-2">{t("No lessons for this chapter")}</h2>
                )}
                {chapter.all_children?.length >= 1 &&
                  chapter.all_children.map((childChapter, childIndex) => (
                    <Chapters key={childIndex} chapters={[childChapter]} />
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      {showNormalLessonLoading && <Loading />}
    </>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function BasicTabs({ reviews, chapters, course, executionId }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const user = JSON.parse(window.localStorage.getItem("userData"));
  const expire_date = window.localStorage.getItem("expire_date");
  const maxRate = 5;

  const calcRate = (rateCount) => {
    if (rateCount) {
      const stars = [];
      for (let i = 0; i < parseInt(rateCount); i++) {
        stars.push(
          <i className="fa-solid fa-star text-warning text-sm" key={i}></i>
        );
      }
      for (let i = 0; i < parseInt(maxRate - rateCount); i++) {
        stars.push(
          <i className="fa-regular fa-star text-warning text-sm" key={i + parseInt(rateCount)}></i>
        );
      }
      return (
        <span className="d-flex align-items-center">
          {stars}
        </span>
      );
    }
  };

  function logChapterNames(chapters, level = 0) {
    chapters.forEach((chapter) => {
      console.log(`${'  '.repeat(level)}${chapter.name}`); // Indentation based on level

      if (chapter.all_children && chapter.all_children.length > 0) {
        logChapterNames(chapter.all_children, level + 1); // Recursively call for children
      }
    });
  }

  useEffect(() => {
    if (chapters) {
      logChapterNames(chapters);
    }
  }, [chapters]);

  return (
    // <Box sx={{ width: '100%' }} className="custom-rtl-direction">
    <Box sx={{ width: '100%' }} className="">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="course-content-tabs">
          <Tab label={t("Course Details")} {...a11yProps(0)} />
          <Tab label={t("Reviews")} {...a11yProps(1)} />
          <Tab label={t("Content")} {...a11yProps(2)} />
          <Tab label={t("About Instructor")} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div className="instructor-content relative">
          <h1 className="relative mb-2 font-bold">{t("About the Course")}</h1>
          <h2 className="relative mb-2 font-bold">{course?.name}</h2>
          <div dangerouslySetInnerHTML={{ __html: t(course.description) }} />
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className="relative max-h-[400px] overflow-y-auto w-full reviews-content">
          <h1 className="mb-2 font-bold">{t("Reviews")}</h1>
          {reviews?.length >= 1
            ? reviews.map((rev, index) => {
                const { comment, rating, student } = rev;
                return (
                  <div className="rating-div relative flex items-center mb-2" key={index}>
                    <img
                      src={student.image_url ? student.image_url : "https://i.pinimg.com/564x/f8/8b/e7/f88be7a950cb50699ce975531693bc45.jpg"}
                      className="w-[60px] h-[80px]"
                      alt="Student"
                    />
                    <div className="relative mx-2">
                      <h2>{student?.name}</h2>
                      <div className="flex">
                        {calcRate(parseInt(rating))} <span className="text-sm">0.{rating}</span>
                      </div>
                      <p className="text-sm">{comment}</p>
                    </div>
                  </div>
                );
              })
            : <h3>{t("No Reviews")}</h3>
          }
          {user && expire_date
            ? <ReviewForm course_id={course?.id} />
            : <p className="text-red-500 my-2">{t("You must log in to be able to review!")}</p>
          }
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <div className="relative max-h-[400px] w-full reviews-content">
          {chapters?.length >= 1
            ? <Chapters chapters={chapters} executionId={executionId} />
            : <h2>{t("No Content Available Yet")}</h2>
          }
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <div className="relative max-h-[400px] overflow-y-auto w-full reviews-content">
          <div dangerouslySetInnerHTML={{ __html: t(course?.user?.baio) }} />
        </div>
      </CustomTabPanel>
    </Box>
  );
}

export default function CustomCourseDetails() {
  const {t} = useTranslation();
  const user = JSON.parse(window.localStorage.getItem("userData"));
  const expire_date = window.localStorage.getItem("expire_date");
  const userToken = window.localStorage.getItem("token");
  const { excutionId } = useParams();
  const { loading , error , data} = useSelector(
    (s) => s.singleCourseSlicer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  const [courseInstructor, setCourseInstructor] = useState({});
  const [reviews, setReviews] = useState([]);
  const [mounted , setMounted] = useState();
  const [showSubscribeForm , setShowSubscribeForm] = useState();
  const [priceAfterOffer , setPriceAfterOffer] = useState();
  const [showFreeLesson , setShowFreeLesson] = useState();
  const [freeVideoUrl , setFreeVideoUrl] = useState();
  const [freeLessonTitle , setFreeLessonTitle] = useState();
  const [freeLesson , setFreeLesson] = useState({});
  const [targetLessonId , setTargetLessonId] = useState();
  const [downloadingFilePercent , setDownloadingFilePercent] = useState(0);
  const [execution , setExecution] = useState({});
  const [chapters , setChapters] = useState([]);
  const {loading:getFreeLessonLoading , data:freeLessonData} = useSelector(
    state => state.shwoLessonSlicer
  )
  const contextApi = useContext(ContextApi);
  const site_global_currency = contextApi?.site_global_currency;
  const [currency , setCurrency] = useState("");
  const [showActivationCourse , setShowActivationCourse] = useState();
  const {
    loading: subscriptionsCoursesLoading,
    data: subscriptionsCoursesData,
    error: subscriptionsCoursesError,
  } = useSelector((s) => s.subscriptionsCoursesSlicer);
  const globalLessonsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons`;
  const getAllLessons = async () => {
    dispatch (
      getSubscriptionsCourses(
        `${process.env.REACT_APP_PUBLIC_API}/api/course-executions/${excutionId}/lessons`
      )
    )
  };
  const getSingleCourse = () => {
    dispatch(getSingleCourseFunc(excutionId))
  };
  useEffect(() => site_global_currency === "EGP" ?setCurrency("ج.م") : setCurrency(site_global_currency) , [site_global_currency])
  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleCourse();
  }, [excutionId]);
  const [bookingPeriod , setBookingPeriod] = useState("")
  useEffect(() => {
    if(data?.courseExecution) {
      if(data?.courseExecution?.course_details) {
        setCourseInstructor(data?.courseExecution?.course_details?.user)
        setReviews(data?.courseExecution?.course_details?.reviews)
        setChapters(data?.courseExecution?.course_details?.chapters)
        setCourse(data?.courseExecution?.course_details)
        const futureDate = new Date(data?.courseExecution?.end_date_publish);
        const currentDate = new Date();

        const timeDifference = futureDate - currentDate; // Difference in milliseconds

        // Convert the time difference to days, hours, and minutes
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        console.log(`Difference: ${daysDifference} days, ${hoursDifference} hours, ${minutesDifference} minutes`);
        setBookingPeriod(`Booking Period: ${daysDifference} days, ${hoursDifference} hourss`);

      }
      setExecution(data.courseExecution);
      const offerValue = data?.courseExecution?.price - parseInt(data?.courseExecution?.discount)
      setPriceAfterOffer(offerValue);
    }
  },[data])

  useEffect(() => {
    user && expire_date && getAllLessons();
  }, []);
  useEffect(() => {
    subscriptionsCoursesData &&
    subscriptionsCoursesData.lessons &&
    setLessons(subscriptionsCoursesData.lessons);
  },[subscriptionsCoursesData])
  const checkLessonType = (lessonType) => {
      if(lessonType) {
          switch(lessonType) {
              case "video" :
              return <img loading="lazy" src={videoIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "quiz" :
              return <img loading="lazy" src={quizIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "file" :
              return <img loading="lazy" src={fileIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "pdf" :
              return <img loading="lazy" src={pdfIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "rich_text" :
              return <img loading="lazy" src={rich_textIcon} alt="icon" className="relative mr-1 w-[30px]" />
              default :
              return <img loading="lazy" src={videoIcon} alt="icon" className="relative mr-1 w-[30px]" />
          }
      }
  }
  const postCompletingLesson = (lessonId) => {
      const postCompletingLessonApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}/complete`;
    dispatch(postCompletingLessonFunc(postCompletingLessonApi))
  }
  useEffect(() => {
    !loading ? setTimeout(() => setMounted(true) , 1000) : setMounted(false)
  },[loading])

  useEffect(() => {
    freeLessonData && setFreeLesson(freeLessonData);
  },[freeLessonData])

  const {
    loading: profileLoading,
    data: profileData,
    error: profileError,
  } = useSelector((s) => s.profileSlicer);
  const [studentSubscription , setStudentSubscriptions] = useState([]);
  const [haveThisCourse , setHaveThisCourse] = useState();
  useEffect(() => {
    setStudentSubscriptions(profileData?.subscriptions)
  },[profileData])
  useEffect(() => {
    if(studentSubscription) {
      const find = studentSubscription.find(ex => ex?.course_execution?.course_id === execution?.course_id);
      find ? setHaveThisCourse(true) : setHaveThisCourse(false);
    }
  },[studentSubscription , execution])
  
  return (
    <>
      <PageHeader headerImg={imgHeader} title={course && course.name && course.name} loadingPage={loading} />
      <section className="course-details">
        <div className="container">
          {loading ? (
            <>
              <div className="row">
                <div className="text-center py-1">
                  <CustomLoaders />
                  <CustomLoaders />
                  <CustomLoaders />
                  <CustomLoaders />
                </div>
              </div>
            </>
          ) : (
            <>
              {error ? (
                <>
                  <div className="row">
                    <div className="text-center py-1">
                      <h2>{error}</h2>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {data && course && (
                    
                      <div className="details-content relative w-full flex flex-col">
                        <div className="relative content-container w-[90%] flex-col md:flex-row mx-auto flex justify-between py-3 translate-y-[-100px]">

                          {/* <div className={`details-side-bar relative flex flex-col col-sm-12 col-md-4 py-3 sm:mb-[20px] md:mb-0 rounded-md transition-all duration-500 p-2 ${mounted ? "translate-y-0 opacity-100" : "translate-y-[20px] opacity-0"} bg-white`} style={{ boxShadow: "1px 1px 54px #f0f0f0" }}>
                            <div className="relative rounded-md w-full h-[200px] overflow-hidden">
                              <img src={course?.image_url ? course.image_url : dfCourseImg} alt={t("course image")} className="absolute top-0 left-0 w-full h-full" />
                            </div>
                            <ul>
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <AttachMoneyIcon className="text-primary" />
                                </div>
                                <p className="m-0 text-sm mx-1">
                                  <span className="font-bold ml-1">{t("Price")}:</span>
                                  {execution && execution.price} {currency}
                                </p>
                              </li>
                              {execution.discount && (
                                <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center ">
                                  <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                    <DiscountIcon className="text-primary" />
                                  </div>
                                  <p className="m-0  text-sm mx-1">
                                    <span className="font-bold ml-1">{t("Discount")}:</span>
                                    {execution.discount} {currency}
                                  </p>
                                </li>
                              )}
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <CastForEducationIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold ml-1">{t("Number of Chapters")}:</span>
                                  {data?.chapters_count}
                                </p>
                              </li>
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <BookmarkAddedIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold mr-1">{t("Booking Period")}:</span>
                                  {execution?.start_date_publish?.replace(/-/g, "/")}
                                  {" - "}
                                  {execution?.end_date_publish?.replace(/-/g, "/")}
                                </p>
                              </li>
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <EventNoteIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold mr-1">{t("Course Duration")}:</span>
                                  {execution?.start_date_student?.replace(/-/g, "/")}
                                  {" - "}
                                  {execution?.end_date_student?.replace(/-/g, "/")}
                                </p>
                              </li>
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <CategoryIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold ml-1">{t("Category")}:</span>
                                  {course?.category?.name}
                                </p>
                              </li>
                            </ul>
                            {haveThisCourse ? (
                              <Button variant="contained" className="mt-2 bg-primary py-2" onClick={() => navigate(`/lessons/${excutionId}`)}>
                                <SmartDisplayIcon className="mx-2" />
                                {t("View Course")}
                              </Button>
                            ) : (
                              <div className="relative flex flex-col">
                                <Button variant="contained" className="my-2 py-2" onClick={() => {
                                  if (user && expire_date && userToken) {
                                    navigate(`/checkout/${excutionId}`);
                                  } else {
                                    navigate(`/login`);
                                  }
                                }}>
                                  <ContactlessIcon className="mx-1" />
                                  {t("Subscribe Now")}
                                </Button>
                                <Button variant="outlined" className="text-slate-800 my-2 py-2" onClick={() => setShowActivationCourse(true)}>
                                  <QrCode2Icon className="mx-1" />
                                  {t("Activate")}
                                </Button>
                              </div>
                            )}
                          </div> */}
                          <div className={`about-course relative transition-all duration-500 flex flex-col col-sm-12 col-md-7 ${mounted ? "translate-y-0 opacity-100" : "translate-y-[-20px] opacity-0"} bg-white my-[20px] md:my-0`} style={{boxShadow: "1px 1px 54px #f0f0f0"}}>
                              <div className="relative flex items-center col-sm-12  my-3">
                                <div className="instructor bottom-0  flex items-center m-2">
                                  <img src={courseInstructor?.image_url ? courseInstructor.image_url : "https://i.pinimg.com/564x/f8/8b/e7/f88be7a950cb50699ce975531693bc45.jpg"} alt="instructor-img" className="w-[50px] h-[50px] rounded-[50%] mx-2" />
                                  <div className="flex flex-col">
                                    <h2>{courseInstructor?.name}</h2>
                                    <p className="text-sm">{course?.name}</p>
                                  </div>
                                </div>
                              </div>
                              <BasicTabs
                                reviews={reviews}
                                chapters = {chapters}
                                course = {course}
                                executionId={excutionId}
                              />
                          </div>
                          <div className={`details-side-bar relative flex flex-col col-sm-12 col-md-4 py-3 sm:mb-[20px] md:mb-0 rounded-md transition-all duration-500 p-2 ${mounted ? "translate-y-0 opacity-100" : "translate-y-[20px] opacity-0"} bg-white`} style={{ boxShadow: "1px 1px 54px #f0f0f0" }}>
                            <div className="relative rounded-md w-full h-[200px] overflow-hidden">
                              <img src={course?.image_url ? course.image_url : dfCourseImg} alt={t("course image")} className="absolute top-0 left-0 w-full h-full" />
                              <p className="rounded-lg bg-primary text-white p-2 absolute bottom-0 text-sm"><BookmarkAddedIcon/> {bookingPeriod}</p>
                            </div>
                            <ul>
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <AttachMoneyIcon className="text-primary" />
                                </div>
                                {
                                  execution.discount
                                  ?
                                  <>
                                    <p className="m-0 text-sm mx-1">
                                      <span className="font-bold ml-1">{t("Price")}:</span>
                                      {execution && execution.price - execution.discount} {currency}
                                    </p>
                                    <del className="text-danger mx-2 text-sm">
                                      {execution.price} {currency}
                                    </del>
                                  </>
                                  :
                                  <p className="m-0 text-sm mx-1">
                                    <span className="font-bold ml-1">{t("Price")}:</span>
                                    {execution && execution.price - execution.discount} {currency}
                                  </p>

                                }
                              </li>
                              {/* {execution.discount && (
                                <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center ">
                                  <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                    <DiscountIcon className="text-primary" />
                                  </div>
                                  <p className="m-0  text-sm mx-1">
                                    <span className="font-bold ml-1">{t("Discount")}:</span>
                                    {execution.discount} {currency}
                                  </p>
                                </li>
                              )} */}
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <CastForEducationIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold ml-1">{t("Number of Lessons")}:</span>
                                  {data?.lessons_count}
                                </p>
                              </li>
                              {/* <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <BookmarkAddedIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold mr-1">{t("Booking Period")}:</span>
                                  {execution?.start_date_publish?.replace(/-/g, "/")}
                                  {" - "}
                                  {execution?.end_date_publish?.replace(/-/g, "/")}
                                </p>
                              </li> */}
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <EventNoteIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold mr-1">{t("Course Duration")}:</span>
                                  {execution?.start_date_student?.replace(/-/g, "/")}
                                  {" - "}
                                  {execution?.end_date_student?.replace(/-/g, "/")}
                                </p>
                              </li>
                              <li className="relative border-b border-slate-200 mb-2 py-2 px-3 flex items-center  ">
                                <div className="relative p-2 rounded-[50%] border flex justify-center items-center">
                                  <CategoryIcon className="text-primary" />
                                </div>
                                <p className="m-0  text-sm mx-1">
                                  <span className="font-bold ml-1">{t("Category")}:</span>
                                  {course?.category?.name}
                                </p>
                              </li>
                            </ul>
                            {haveThisCourse ? (
                              <Button variant="contained" className="mt-2 bg-primary py-2" style={{backgroundColor : "#139897"}} onClick={() => navigate(`/lessons/${excutionId}`)}>
                                <SmartDisplayIcon className="mx-2" />
                                {t("View Course")}
                              </Button>
                            ) : (
                              <div className="relative flex flex-col">
                                <Button variant="contained" className="my-2 py-2" style={{backgroundColor : "#139897"}} onClick={() => {
                                  if (user && expire_date && userToken) {
                                    navigate(`/checkout/${excutionId}`);
                                  } else {
                                    navigate(`/login`);
                                  }
                                }}>
                                  <ContactlessIcon className="mx-1" />
                                  {t("Subscribe Now")}
                                </Button>
                                <Button variant="outlined" className="text-slate-800 my-2 py-2" onClick={() => setShowActivationCourse(true)}>
                                  <QrCode2Icon className="mx-1" />
                                  {t("Activate")}
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                  )
                  }
                </>
              )}
            </>
          )}
        </div>
      </section>
      <SubscirbeForm
        show = {showSubscribeForm}
        onHide = {() => setShowSubscribeForm(false)}
        execution_course_id = {excutionId}
      />
      <ShowVideoModal
        title= {freeLessonTitle}
        videoUrl={freeVideoUrl}
        show = {showFreeLesson}
        onHide = {() => setShowFreeLesson(false)}
        lesson_id = {targetLessonId}
      />
        {
          parseInt(downloadingFilePercent) >= 1 && parseInt(downloadingFilePercent) !== 100
          &&
          <div className="fixed transition-all duration-300 top-0 h-[100vh] left-0 w-full z-[99999999] bg-[#263676] flex flex-col justify-center items-center">
            <div className="relative w-[350px] overflow-hidden h-[40px] rounded-md bg-[#cbd5e182]">
                <span className={`absolute flex justify-center items-center bg-[#0f60a3] text-stone-50 transition-all duration-500 top-0 left-0 h-full`} style={{ width: `${downloadingFilePercent}%` }}>
                    {
                        parseInt(downloadingFilePercent) >= 1
                        &&
                        `${parseInt(downloadingFilePercent)}%`
                    }
                </span>
            </div>
            <p className="text-stone-50 mt-2">جاري التنزيل..</p>
          </div>
        }
      
      <ActivationCodeModal
        show = {showActivationCourse}
        onHide = {() => setShowActivationCourse(false)}
        course_execution_id = {excutionId}
      />
    </>
  );
}

