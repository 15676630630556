import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCoursesFunc } from "../../../../store/slices/courses/coursesSlicer";
import CardCourses from "../../../../component/ui/course-card/CardCourses";
import CustomLoaders from "../../../../component/ui/CustomLoaders";
import faildImg from "../../../../images/planet.png";
import coursesHeader from "../../../../images/courses-header.webp";
import PageHeader from "../../../../component/ui/PageHeader";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SubCategoriesSection from "../categories-section/SubCategoriesSection";

export default function CoursesShow() {
  const { loading: getExecutionsLoading, data: executionsData } = useSelector((s) => s.GetAllCoursesSlicer);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const excutionsApi = `${process.env.REACT_APP_PUBLIC_API}/api/course-executions`;
  const [executionsList, setExecutionsList] = useState([]);
  const myUrl = new URLSearchParams(useLocation().search);
  const categoryId = myUrl.get("category");
  const { t } = useTranslation();
  const {loading:showCategoryLoading , data:categoryData} = useSelector(
    state => state.getSingleCategorySlicer
  )
  const [subCategoriesList , setSubCategoriesList] = useState([])
  useEffect(() => {
    const getData = () => {
      dispatch(fetchAllCoursesFunc(`${excutionsApi}${categoryId ? `?category_id=${categoryId}` : ""}`))
      .then(result => {
        setExecutionsList(result?.payload)
      })
    }
    getData();
  }, [categoryId]);

  // useEffect(() => {
  //   if (executionsData && executionsData.length >= 1) {
  //     setExecutionsList(executionsData);
  //     console.log(executionsData);
  //   }
  // }, [executionsData]);
  // const searchFunc = () => {
  //   let searchApi = `${excutionsApi}${searchValue ? `?search=${searchValue}` : ""}${categoryId ? `&category_id=${categoryId}` : ""}`;
  //   if (searchValue !== "") {
  //     dispatch(fetchAllCoursesFunc(searchApi));
  //   }
  // }

  // useEffect(() => {
  //   if (searchValue === "") {
  //     dispatch(fetchAllCoursesFunc(`${excutionsApi}${categoryId ? `?category_id=${categoryId}` : ""}`));
  //   }
  // }, [searchValue]);
  useEffect(() => {
    if(categoryData) {
      setSubCategoriesList(categoryData?.data?.category?.all_children)
    }
  }, [categoryData])

  return (
    <>
      <PageHeader title={t("Categories with courses")} headerImg={coursesHeader} />
      {
        
      }
      <section className="courses-section">
        {
          categoryId
          ?
          <div className="container-courses mx-auto sm:w-[90%]">
            <SubCategoriesSection urlId={categoryId}/>
          </div>
          :
          ""
        }
        {
          executionsList.length >= 1
          ?
          <div className="container-courses mx-auto sm:w-[90%]">
            <div className="section-title text-center">
              <h2 className="section-title__title">{t("Explore Our Available Courses")}</h2>
              {/* <span className="section-title__tagline text-sm"> {t("We offer a variety of educational courses in different fields to meet your learning needs and interests.")}</span> */}
            </div>
            {/* <div className="row">
              <div className="" dir="rtl">
                <div className="col-xl-12 relative col-lg-12 col-md-12 col-sm-12">
                  <div className="relative flex items-center">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        searchFunc();
                      }}
                    >
                      <div className="input-group flex flex-nowrap rounded-md items-center search-area w-auto my-2 border">
                        <button className="border-none outline-none bg-transparent p-3" type="submit">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                        <input
                          type="text"
                          className="bg-transparent shadow-none placeholder:text-slate-500 border-none w-full md:w-[400px]"
                          placeholder={t("Search for Courses")}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="">
              {getExecutionsLoading ? (
                <>
                  <div className="text-center py-1 my-4 h-[400px] flex justify-center items-center">
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                  </div>
                </>
              ) : (
                <>
                  {
                    executionsData && executionsList && executionsList.length >= 1 ? (
                        <div className="row ">
                          {executionsList.map((excution, index) => {
                            const {course , discount , date_student_status} = excution
                            return (
                              <CardCourses
                                key={index}
                                execution={excution}
                                executionsArray = {executionsList}
                                overlayText={course.semester}
                                teacher={course.user.name}
                                title={course.name}
                                excutionId={excution.id}
                                price={excution.price}
                                courseDiscount = {discount}
                                startPublishForAnyoneDate = {excution.start_date_publish}
                                endPublishForAnyoneDate = {excution.end_date_publish}
                                startPublishForStudentDate = {excution.start_date_student}
                                endPublishForStudentDate = {excution.end_date_student}
                                courseImg={course.image_url}
                                courseInstructor={course.user}
                                typeCourse={course.type}
                                courseStatus = {course.publish}
                                categoryName = {course.category && course.category.name && course.category.name}
                                activeStatus={date_student_status}
                                rating={course.rating}
                            />
                            )
                          })}
                        </div>
                    )
                      :
                      <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                        <img
                          loading="lazy"
                          src={faildImg}
                          alt={t("Failed Image")}
                          className="w-[150px] mb-3"
                        />
                        <p>
                          {t("No Courses Available")}!
                        </p>
                      </div>
                  }
                </>
              )}
            </div>
          </div>
          :
          ""
        }
        {
          (subCategoriesList.length < 1 && executionsList.length < 1)
          ?
          <div className="container-courses mx-auto sm:w-[90%] min-h-[50vh] flex justify-center items-center">
            <h3 className="text-center">{t("Sorry, There is no content!")}</h3>
          </div>
          :
          ""
        }
      </section>
    </>
  );
}
