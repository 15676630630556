import "./show-course.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getLessonData } from "../../store/slices/lessons/showLessonSlicer";
import { useEffect, useState } from "react";
import CustomLoaders from "../../component/ui/CustomLoaders";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import QuizIcon from '@mui/icons-material/Quiz';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, ListItem, Tabs, Tab } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '@mui/material/styles';
import { List } from "antd";
import { showSingleLessonFunc } from "../../store/slices/lessons/ShowSingleLessonSlicer";
import LessonViewer from "./LessonViewer";
import reqImage from "../../images/icon-human-resource-management-hiring-concept-job-interview-recruitment-agency-3d-illustration_250043-431-removebg-preview.png";
import dfCourseImg from "../../images/df-course-img.webp"
import empty1 from "../../images/calendar-with-checklist-date-schedule-3d-icon_107791-15691-removebg-preview.png"
import empty2 from "../../images/blank-sheet-with-magnifying-glass-icon-3d-rendering-3d-render-cartoon-icon-style-concept_457716-1702-removebg-preview.png"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { targetLessonWhichComplete } from "../../store/slices/lessons/SetLessonWhichWillCompletItSlicer";
import { toast } from "react-toastify";
import RequirmentsTable from "./RequirmentsTable";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ShowingLessonHistoryTable from "./ShowingLessonHistoryTable";
import ShowingLessonSchadulesTable from "./ShowingLessonSchadulesTable";
import { getAttmptFunc } from "../../store/slices/attempt-actions/GetAttemptSlicer";
import CycleGrade from "./CycleGrade";
import { useTranslation } from "react-i18next";

const LessonBox = ({lesson , subscriptionStatus ,
  showLessonFunction,
}) => {
  const {t} = useTranslation();
  const  {loading : postAnswersLoading , data:submitQuizResponse} = useSelector(
    state => state.postStudentAnswersSlicer
  );
  const {loading:getNormalLessonLoading , data:lessonData} = useSelector(
    state => state.showSingleLessonSlicer
  )
  const {loading:getExpireVideoLoading , data:expireData} = useSelector(
    state => state.getExpireVideoSlicer
  )
  const {loading:getExpirePdfLoading , data:expirePdf} = useSelector(
    state => state.getExpirePdfSlicer
  )
  const {loading:getSteamResponseLoading , data:streamResponse} = useSelector(
    state => state.showStreamLessonSlicer
  )
  const {data:targetLessonData} = useSelector(
    state => state.setLessonWhichWillCompletItSlicer
  )
  const [veiwCount , setViewCount] = useState(lesson?.completed_students?.length)
  const switchLessonType = (type) => {
    switch (type) {
      case "video":
        return <PlayCircleFilledIcon className="text-primary"/>;
      case "youtube":
        return <PlayCircleFilledIcon className="text-primary"/>;
      case "pdf":
        return <PictureAsPdfIcon className="text-primary"/>;
      case "file":
        return <FolderZipIcon className="text-primary"/>;
      case "quiz":
        return <QuizIcon className="text-primary"/>;
      case "rich_text":
        return <AssignmentIcon className="text-primary"/>;
      default:
        return <PictureAsPdfIcon className="text-primary"/>;
    }
  };
  const handleShowLesson = (lesson) => {
    showLessonFunction(lesson , parseInt(veiwCount))
  }
  useEffect (() => {
    if(submitQuizResponse?.success && lesson?.type === "quiz" && targetLessonData?.id === lesson?.id) {
      setViewCount((prev => prev+1))
    }
  },[submitQuizResponse , lesson , targetLessonData])
  useEffect (() => {
    if(lessonData?.success && lesson?.type === "rich_text" && targetLessonData?.id === lesson?.id) {
      setViewCount((prev => prev+1))
    }
  },[lessonData , lesson , targetLessonData])
  useEffect (() => {
    if(lessonData?.data?.file_url && lesson?.type === "video" && targetLessonData?.id === lesson?.id) {
      setViewCount((prev => prev+1))
    }
  },[lessonData , lesson , targetLessonData])
  useEffect (() => {
    if(lessonData?.data?.file_url && lesson?.type === "pdf" && targetLessonData?.id === lesson?.id) {
      setViewCount((prev => prev+1))
    }
  },[lessonData , lesson , targetLessonData])
  useEffect (() => {
    if(lessonData?.data?.file_url && lesson?.type === "file" && targetLessonData?.id === lesson?.id) {
      setViewCount((prev => prev+1))
    }
  },[lessonData , lesson , targetLessonData])
  return (
    <>
      <List>
        <ListItem
          className={
            `cursor-pointer flex justify-between items-center   
            ${postAnswersLoading ||
            getNormalLessonLoading ||
            getExpireVideoLoading ||
            getExpirePdfLoading ||
            getSteamResponseLoading ? "pointer-events-none" : ""}`
          }
          // onClick={() => handleShowLesson(lesson)}
          onClick={() => handleShowLesson(lesson)}
        >
          <Typography>{switchLessonType(lesson.type)} {lesson.name}</Typography>
          {
            subscriptionStatus !== 1
            ?
            (lesson?.can_view_without_pay === 1 ? <>{t("Free")}</> : "")
            :
            ""
          }
          {
            lesson?.view_count === 0
            ?
            <AllInclusiveIcon className="text-primary opacity-80"/>
            :
            <span className={`${parseInt(veiwCount) >= parseInt(lesson?.view_count) ? "text-red-500" : ""}`}>
              <RemoveRedEyeIcon className="text-[15px] opacity-75 mx-1"/>
              {lesson?.view_count} / {veiwCount}
            </span>
          }
        </ListItem>
      </List>
    </>
  )
}
function Chapters({ 
  chapters , 
  logLesson , 
  subscriptionStatus ,
  showLessonFunction}) {
  const {t} = useTranslation();
  return (
    <>
    <Box className="p-0 m-0">
      {chapters?.length >= 1 &&
        chapters.map((chapter, index) => (
          // chapter.lessons_details?.length >= 1
          // ||
          // chapter.all_children?.length >= 1
          // ?
          <Accordion className="p-0 mb-3 border-none before:bg-transparent shadow-none">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="text-stone-50"/>}
                aria-controls={`panel-${chapter.id}-content`}
                id={`panel-${chapter.id}-header`}
                className="bg-primary rounded-lg"
              >
                <Typography className="font-bold text-lg text-stone-50"><InsertChartIcon className="mx-2"/>{chapter.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {chapter.lessons_details?.length >= 1 
                ?
                chapter.lessons_details.map((lesson, lessonIndex) => (
                  <LessonBox 
                  key={lessonIndex}  
                  lesson={lesson} 
                  subscriptionStatus={subscriptionStatus} 
                  logLesson={logLesson}
                  targetLessonId = {lesson?.id}
                  showLessonFunction = {showLessonFunction}
                  />
                ))
                :
                <h2 className="my-2">{t("لا يوجد دروس لهذا الفصل")}</h2>
              }
                {chapter.all_children?.length >= 1 &&
                chapter.all_children.map((childChapter, childIndex) => (
                  <Chapters 
                  key={childIndex} 
                  chapters={[childChapter]} 
                  logLesson={logLesson} 
                  subscriptionStatus={subscriptionStatus}
                  showLessonFunction = {showLessonFunction}
                  />
                ))}
              </AccordionDetails>
          </Accordion>
          // :
          // <List className="bg-primary mb-3">
          //   <ListItem>
          //     <Typography className="font-bold text-lg text-stone-50"><InsertChartIcon className="mx-2"/>{chapter.name}</Typography>
          //   </ListItem>
          //   {chapter.all_children && chapter.all_children.length >= 1 && (
          //     <Chapters chapters={chapter.all_children} logLesson={logLesson} subscriptionStatus={subscriptionStatus}/>
          //   )}
          // </List>
        ))}
    </Box>
    </>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BasicTabs() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {data:targetLessonData} = useSelector(
    state => state.setLessonWhichWillCompletItSlicer
  )
  const {loading:getQUizResultLoading , data:quizDataAfterSubmit} = useSelector(
    state => state.getAttemptSlicer
  )
  const [value, setValue] = useState(0);
  const {loading:getNormalLessonLoading , data:lessonData} = useSelector(
    state => state.showSingleLessonSlicer
  )
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [quizeFinalScore , setQuizeFinalScore] = useState(null)
  const [studentQuizScore , setStudentQuizScore] = useState(null)
  const [quizeTringTimesList , setQuizeTringTimesList] = useState([])
  useEffect(() => {
    console.log(quizDataAfterSubmit)
    if(quizDataAfterSubmit?.quizzes) {
      const quizObj = quizDataAfterSubmit?.quizzes;
      setQuizeTringTimesList(quizObj)
      const score = quizObj?.score;
      const passedStatus = quizObj?.status_passed;
      const questionsArray = quizObj?.questions;
      const allPivotObjectsOfQ = quizObj?.questions?.flatMap(Q => Q.grade);
      const allGradeOfQ = allPivotObjectsOfQ?.length >= 1 && allPivotObjectsOfQ.reduce((acc , current) => acc + current , 0);
      console.log(allGradeOfQ)
      setQuizeFinalScore(allGradeOfQ)
      setStudentQuizScore(score)
    }
  } , [quizDataAfterSubmit]);

  useEffect(() => {
    const quizResultsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${targetLessonData?.id}/quizzes`;
    const handleGetAnswersGrades = () => {
        console.log(targetLessonData)
        dispatch(getAttmptFunc(quizResultsApi))
        .then(result => {
            if(result?.payload?.lesson) {
                const quizObj = result?.payload?.quizzes[0];
                const score = quizObj?.score;
                const passedStatus = quizObj?.status_passed;
                const questionsArray = quizObj?.questions;
                const allPivotObjectsOfQ = quizObj?.questions?.flatMap(Q => Q.grade);
                const allGradeOfQ = allPivotObjectsOfQ?.length >= 1 && allPivotObjectsOfQ.reduce((acc , current) => acc + current , 0);
                console.log(allGradeOfQ)
                setQuizeFinalScore(allGradeOfQ)
                setStudentQuizScore(score)
            }
        })
    }
    (targetLessonData?.type === "quiz" && targetLessonData?.quizzes?.length >= 1) && handleGetAnswersGrades()
  },[targetLessonData])
  return (
    // <Box sx={{ width: '100%' }} className="custom-rtl-direction">
    <Box sx={{ width: '100%' }} className="">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="course-content-tabs">
          <Tab label={t("About Lesson")} {...a11yProps(0)} disabled={!targetLessonData}/>
          <Tab label={t("Requirments")} {...a11yProps(1)} disabled={!targetLessonData}/>
          <Tab label={t("Views List")} {...a11yProps(2)} disabled={!targetLessonData}/>
          <Tab label={t("Schedules")} {...a11yProps(3)} disabled={!targetLessonData}/>
          {
            targetLessonData?.type === "quiz"
            ?
            <Tab label={t("Result Attempts")} {...a11yProps(4)} disabled={!targetLessonData}/>
            :
            ""
          }
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      <div className="relaive">
        {
          getNormalLessonLoading
          ?
          <>{t("loading")}...</>
          :
          <>
            <h1 className="relative mb-2 font-bold">{t("About This Lesson")}</h1>
            <h2 className="relative mb-2 font-bold">{targetLessonData?.name}</h2>
            <div dangerouslySetInnerHTML={{__html: targetLessonData?.description}} />
          </>
        }
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {
          getNormalLessonLoading
          ?
          <>{t("loading")}...</>
          :
          (
            targetLessonData?.requirements?.length >= 1
            ?
            <RequirmentsTable/>
            :
            <div className="relative flex flex-col justify-center items-center col-sm-12">
              <img src={reqImage} alt="required img" className="w-[200px] mb-2"/>
              {t("No Requirments")}!
            </div>
          )
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {
          getNormalLessonLoading
          ?
          <>{t("loading")}...</>
          :
          (
            targetLessonData?.completed_students?.length >= 1
            ?
            <ShowingLessonHistoryTable/>
            :
            <div className="relative flex flex-col justify-center items-center col-sm-12">
              <img src={empty2} alt="required img" className="w-[200px] mb-2"/>
              {t("No Views")}!
            </div>
          )
        }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {
          getNormalLessonLoading
          ?
          <>{t("loading")}...</>
          :
          (
            targetLessonData?.lesson_schadules?.length >= 1
            ?
            <ShowingLessonSchadulesTable/>
            :
            <div className="relative flex flex-col justify-center items-center col-sm-12">
              <img src={empty1} alt="required img" className="w-[200px] mb-2"/>
              {t("No Schedules")}!
            </div>
          )
        }
      </CustomTabPanel>
      {
        quizDataAfterSubmit?.quizzes
        ?
        <CustomTabPanel className={"max-g-[400px] overflow-y-auto"} value={value} index={4}>
          <div className="relative max-h-[400px] overflow-y-auto custom-scroll">
            {
              getQUizResultLoading
              ?
              <>{t("loading")}...</>
              :
              (
                quizeTringTimesList?.length >= 1
                ?
                quizeTringTimesList.map((time , index) => (
                <div key={index} className="relative w-full flex flex-col justify-center items-center border-b mb-2">
                  <span className="absolute top-0 left-0 bg-primary text-stone-50 p-1 text-sm rounded-md">( {index+1} ) {" "} {t("رقم المحاولة")}</span>
                  <CycleGrade studentGrade={time.score} quizeFinalScore={100}/>
                  <p className="my-2">{t("Percentage Grade")}</p>
                  {
                    time.note
                    &&
                    <div className="note-div relative w-full my-2">
                      <Accordion className="p-0 mb-3 border-none before:bg-transparent">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon className="text-stone-50"/>}
                          aria-controls={`panel-${time.id}-content`}
                          id={`panel-${time.id}-header`}
                          className="bg-[#176686]"
                        >
                          <Typography className="font-bold text-lg text-stone-50"><EditNoteIcon className="mx-2"/><span className="font-bold">{t("ملاحظات المصحح")}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails className="relative flex items-center">
                          <SpeakerNotesIcon/>
                          <h2 className=" py-2 mx-2">{time.note}</h2>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  }
                </div>
                ))
                :
                <h2>{t("No Attempts")}</h2>
              )
            }
          </div>
        </CustomTabPanel>
        :
        ""
      }
    </Box>
  );
}

export default function ShowCoursePage() {
  const {t} = useTranslation();
  const { executionId } = useParams();
  const location = useLocation();
  const [subStatus, setSubStatus] = useState(null);
  const token = window.localStorage.getItem("token")
  const exApi = `${process.env.REACT_APP_PUBLIC_API}/api/course-executions/${executionId}/get-started`;
  const lessonsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons`;
  const expireApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons`;
  const dispatch = useDispatch();
  const { loading: getExLoading, data: exData } = useSelector(state => state.shwoLessonSlicer);
  const { data: normalLessonData } = useSelector(state => state.showSingleLessonSlicer);
  const {data:targetLessonData} = useSelector(
    state => state.setLessonWhichWillCompletItSlicer
  )
  const [lesson, setLesson] = useState({});
  const [lessonpdf, setLessonpdf] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [showNormalLessonDataMessage , setNormalLessonDataMessage] = useState(null)
  const getEx = () => {
    dispatch(getLessonData(exApi));
  };
  useEffect(() => {
    getEx();
  }, []);

  useEffect(() => {
    if (exData) {
      const courseChapters = exData?.courseExecution?.course?.chapters;
      setChapters(courseChapters);
    }
  }, [exData]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const subValue = searchParams.get('sub_status');
    subValue && setSubStatus(+subValue);
  }, [location]);
  useEffect(() => {
    console.log(targetLessonData)
  },[targetLessonData])
  const handleShowNormalLesson = (lesson) => {
    lesson?.type !== "quiz"
    &&
    dispatch(showSingleLessonFunc(`${lessonsApi}/${lesson?.id}`))
    .then(result => {
      console.log(result)
      result.payload?.message === "auth.must_complete_required" ? setNormalLessonDataMessage("يجب تخطي الدروس المحدده اولا!") : setNormalLessonDataMessage(null)
      !result.payload?.data?.lesson && setLesson(lesson)
      // result.payload?.data?.lesson && dispatch(postCompletingLessonFunc(`${lessonsApi}/${lesson?.id}/complete`));
    })
  }
  useEffect(() => {
    normalLessonData && setLesson(normalLessonData?.data?.lesson);
  }, [normalLessonData]);


  const showLessonFunc = (lesson) => {
    dispatch(targetLessonWhichComplete(lesson));
    dispatch(showSingleLessonFunc(`${lessonsApi}/${lesson?.id}`))
    .then(result => {
      if(lesson?.type === "file") {
        if(result?.payload?.data?.file_url) {
          const blob = new Blob([result.payload?.data], { type: "application/zip" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${lesson?.name}.zip`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Clean up the DOM
          window.URL.revokeObjectURL(url); // Free up memory
        }
      }
    })
  }
  
  const handleShowLesson = (lesson , watchedCount) => {
    if(watchedCount >= parseInt(lesson?.view_count) &&  parseInt(lesson?.view_count) !== 0) {
      dispatch(targetLessonWhichComplete(lesson));
      toast.warning(t("You Have Reached The Max Times To Showing This Lesson"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }else {
      if(!subStatus) {
        showLessonFunc(lesson)
      }else {
        if(lesson?.can_view_without_pay === 1) {
          showLessonFunc(lesson)
        }else {
            toast.error(t("You Should Subscripe"), {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        }
      }
    }
  } 
  useEffect(() => setLesson({}) , [])
  return (
    <>
    {/* <PageHeader title={"محتوي الكورس"} /> */}
    {
      getExLoading 
      ? 
      (
        <div className="text-center py-1 h-[100vh] flex justify-center items-center">
          <CustomLoaders />
          <CustomLoaders />
          <CustomLoaders />
          <CustomLoaders />
        </div>
      ) 
      : 
      (
        exData
        &&
        <div className="page-content min-h-[100vh] relative py-4">
          <div className="container">
            <div className="row">
              <div className="sidebar-content col-sm-12 col-md-8">
                {
                  !showNormalLessonDataMessage
                  ?
                  <LessonViewer 
                    lesson={lesson}
                  />
                  :
                  <div className="relative col-sm-12 flex flex-col justify-center items-center min-h-[60vh]">
                    <img src={reqImage} alt="required img" className="w-[300px] mb-2"/>
                    {showNormalLessonDataMessage}
                  </div>
                }
                {
                  targetLessonData && lesson && Object.keys(lesson).length >= 1
                  &&
                  <div className="content-info-tabs relative col-sm-12">
                    <BasicTabs lesson={targetLessonData} />
                  </div>
                }
              </div>
              <div className={`custom-scroll relative col-sm-12 col-md-4 py-2 overflow-y-auto md:h-screen md:sticky md:shadow-lg md:shadow-slate-300 md:top-0 md:right-0`} style={{ top: 0 }}>
                <div className="course-info relative col-sm-12 mb-2">
                  <div className="relative rounded-md w-full h-[200px] overflow-hidden"
                  // style={{aspectRatio : "1 / 5"}}
                  >
                    <img src={exData?.courseExecution?.course?.image_url ? exData?.courseExecution?.course.image_url : dfCourseImg} alt="course img" className="absolute top-0 left-0 w-full h-full" />
                  </div>
                  <div className="my-2">
                    <h2 className="">{exData?.courseExecution?.course?.name}</h2>
                    <p className="text-sm">{exData?.courseExecution?.title}</p>
                  </div>
                  <p className="relative flex items-center my-2">
                    {/* {t("Instructor")} :  */}
                    <img src={exData?.courseExecution?.course?.user?.image_url}  alt="instructor" className="relative w-[50px] h-[50px] rounded-[50%]"/>
                    {" "}
                    <spann className="relative mx-2 font-bold text-slate-700">{exData?.courseExecution?.course?.user?.name}</spann>
                  </p>
                  {/* <div className="relative my-1 max-h-[300px] overflow-y-auto custom-scroll">
                    <h3 className="relative font-bold my-1">{t("About Instructor")}:</h3>
                    <div dangerouslySetInnerHTML={{__html: exData?.courseExecution?.course?.user?.baio}} />
                  </div> */}
                  <p className="">
                    {t("Chapters Count")} :
                    {" "} 
                    ( {chapters?.length} )
                  </p>
                </div>
                <Chapters
                  chapters={chapters}
                  showLessonFunction = {handleShowLesson}
                  subscriptionStatus={subStatus}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    </>
  )
}