import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
export default function PdfViewer({ url }) {
    const {t} = useTranslation();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const {loading:getExpireVideoLoading , data:expireData} = useSelector(
        state => state.getExpirePdfSlicer
    )
    const [pdfUrl , setpdfUrl] = useState(null);

    useEffect(() => {
        console.log(url)
        url && setpdfUrl(url)
    },[url])
    return (
        url && (
            <>
            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={`${pdfUrl}`} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
            <object></object> */}
            <div className='relative h-[700px]'>
            <object data={`${pdfUrl}`} type="application/pdf" width="100%" height="100%" >
                <p>{t("Your browser does not support PDFs.")} 
                    <a href={`${pdfUrl}`}>{t("Download the PDF")}</a>.
                </p>
            </object>
            </div>
            </>
        )
    );
}

