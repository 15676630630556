// import { Link, useNavigate } from "react-router-dom";
// import  teacherImg from "../../../images/presentation.png";
// import  discountIcon from "../../../images/discounts.png";
// import "./course-card.css"
// import { useContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import ActivationCodeModal from "../../global/global-modals/ActivationCodeModal";
// import dfCourseImg from "../../../images/df-course-img.webp"
// import { Button } from "@mui/material";
// import ContextApi from "../../../store/slices/Context";
// import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
// import { useTranslation } from "react-i18next";
// export default function CardCourses({ execution ,  courseDiscount ,teacher,title,excutionId,price , courseImg , courseInstructor , inSwipper , activeStatus}) {
//   const navigate = useNavigate();
//   const {t} = useTranslation();
//   const {
//     loading: profileLoading,
//     data: profileData,
//     error: profileError,
//   } = useSelector((s) => s.profileSlicer);
//   const contextApi = useContext(ContextApi)
//   const site_global_currency = contextApi?.site_global_currency;
//   const [studentSubscription , setStudentSubscriptions] = useState([]);
//   const [showActivationCourse , setShowActivationCourse] = useState();
//   const [haveThisCourse , setHaveThisCourse] = useState();
//   useEffect(() => {
//     setStudentSubscriptions(profileData?.subscriptions)
//   },[profileData])
//   useEffect(() => {
//     if(studentSubscription) {
//       const find = studentSubscription.find(ex => ex?.course_execution?.course_id === execution?.course_id);
//       find ? setHaveThisCourse(true) : setHaveThisCourse(false);
//     }
//   },[studentSubscription , execution])
//   return (
//     <>
//       <div className={`course-card ${inSwipper ? "col-sm-12" : "col-sm-12 col-md-4 col-lg-3"}  transition-all duration-500 relative my-4 group bg-white`}
      
//       // onMouseOver={() => setOnHover(true)}
//       // onMouseLeave={() => setOnHover(false)}
      
//       >
//         {
//           courseDiscount
//           ?
//           <div className="absolute top-[10px] left-[20px] z-[99]">
//             <img loading="lazy" src={discountIcon} alt="icon" className="w-[30px] rotate-[-50deg]" />
//           </div>
//           :
//           ""
//         }
//         <div className="course-image relative w-full h-[200px] overflow-hidden rounded-md ">
//           <img loading="lazy"
//             src={`${courseImg !== null ? courseImg : dfCourseImg}`}
//             alt="course img"
//             className="absolute top-0 left-0 w-full h-full transition-all duration-500 group-hover:scale-125 group-hover:rotate-[10deg]"
//           />
//           <div className={`absolute overlay-div top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] overflow-hidden  w-[5px] h-[5px] rounded-[50%] opacity-0 group-hover:w-full group-hover:h-full group-hover:opacity-100 group-hover:rounded-none  bg-[#00000027] flex flex-col justify-center p-2  transition-all duration-500`}>
//           {
//             <Link className="absolute top-0 left-0 w-full h-full"
//               to={`/courses/${excutionId}`}
//             ></Link>
//           }
//           </div>
//         </div>
//         <div className="courses-one__single-content m-0">
//           <div className="courses-one__single-content-overlay-img">
//             <img loading="lazy" src={courseInstructor && courseInstructor.image_url !== null ? courseInstructor.image_url : teacherImg} alt="teacher img" className="w-[40px]" />
//           </div>
//           <h6 className="courses-one__single-content-name">{teacher}</h6>
//           <div className="relative flex justify-between items-start pt-1">
//             <div className="relative flex flex-col">
//               <Link to={`/courses/${excutionId}`} className="courses-one__single-content-title leading-none text-slate-600">
//                 {t(title)}
//               </Link>
//             </div>
//           </div>
//           {
//             courseDiscount
//             ?
//             <div className="relative flex flex-col my-2">
//               <p className="relative text-sm">
//                 {t("السعر")} : 
//                 <span className="font-bold">{parseInt(price) - parseInt(courseDiscount)}  {site_global_currency === "EGP" ? "ج.م" : site_global_currency}</span>
//                 <del className="text-red-500 mr-2">{price} {site_global_currency === "EGP" ? "ج.م" : site_global_currency}</del>
//               </p>
//             </div>
//             :
//             <p className="courses-one__single-content-price py-2 text-slate-600">
//               {t("السعر")} : {price} {site_global_currency === "EGP" ? "ج.م" : site_global_currency}
//             </p>
            
//           }
//           {
//             (haveThisCourse && activeStatus === "active")
//             ?
//             <div className="relative flex justify-center">
//               <Button variant="contained" className="mt-2 bg-primary py-2 col-sm-12"
//               onClick={() => navigate(`/lessons/${excutionId}`)}
//               >
//                 {t("مشاهده الكورس")}
//                 <SmartDisplayIcon className="mx-2"/>
//               </Button>
//             </div>
//             :
//             <div className="relative flex justify-between items-center">
//               <Button variant="contained" className="mx-1"
//                 onClick={() => {
//                   navigate(`/courses/${excutionId}`);
//                 }}
//               >{t("اشترك الان")}</Button>
//               <Button variant="outlined" className="text-slate-800 mx-1"
//                 onClick={() => {
//                   setShowActivationCourse(true);
//                 }}
//               >{t("تفعيل")}</Button>
//             </div>
//           }
//         </div>
//       </div>
//       <ActivationCodeModal
//         show = {showActivationCourse}
//         onHide = {() => setShowActivationCourse(false)}
//         course_execution_id = {excutionId}
//       />
//     </>
//   );
// }


import { Link, useNavigate } from "react-router-dom";
import teacherImg from "../../../images/presentation.png";
import discountIcon from "../../../images/discounts.png";
import "./course-card.css";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ActivationCodeModal from "../../global/global-modals/ActivationCodeModal";
import dfCourseImg from "../../../images/df-course-img.webp";
import { Button } from "@mui/material";
import ContextApi from "../../../store/slices/Context";
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import { useTranslation } from "react-i18next";

export default function CardCourses({ execution, courseDiscount, teacher, title, excutionId, price, courseImg, courseInstructor, inSwipper, activeStatus , rating , customClasses}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    loading: profileLoading,
    data: profileData,
    error: profileError,
  } = useSelector((s) => s.profileSlicer);
  const contextApi = useContext(ContextApi);
  const site_global_currency = contextApi?.site_global_currency;
  const [studentSubscription, setStudentSubscriptions] = useState([]);
  const [showActivationCourse, setShowActivationCourse] = useState();
  const [haveThisCourse, setHaveThisCourse] = useState();

  useEffect(() => {
    setStudentSubscriptions(profileData?.subscriptions);
  }, [profileData]);

  useEffect(() => {
    if (studentSubscription) {
      const find = studentSubscription.find(ex => ex?.course_execution?.course_id === execution?.course_id);
      find ? setHaveThisCourse(true) : setHaveThisCourse(false);
    }
  }, [studentSubscription, execution]);
  const maxRate = 5
  const calcRate = (rateCount) => {
    if(rateCount) {
        for(let i=0 ; i <= parseInt(rateCount); i++) {
            const stars = [];
            for (let i = 0; i < parseInt(rateCount); i++) {
                stars.push(
                  <i className="fa-solid fa-star text-warning"></i>
                );
            }
            for (let i = 0; i < parseInt(maxRate - rateCount); i++) {
                stars.push(
                  <i className="fa-regular fa-star text-warning"></i>
                );
            }
            return (
              <span className="d-flex align-items-center">
                  {stars}
                  {/* <p className="text-dark m-0" style={{transform: "translateY(1px)translateX(2px)"}}>0.{rateCount}</p> */}
              </span>
            );
        }
    }
  }
  return (
    <>
      <div data-aos="fade-up" className={`aos course-card ${inSwipper ? "col-sm-12" : "col-sm-12 col-md-4 col-lg-3"} transition-all duration-500 relative my-4 group bg-white ${customClasses}`}>
        {
        courseDiscount ? 
        (
          <div className="absolute top-[10px] left-[20px] z-[99]">
            <img loading="lazy" src={discountIcon} alt={t("Discount icon")} className="w-[30px] rotate-[-50deg]" />
          </div>
        )
        :
        ""
        }
        <div className="course-image relative w-full h-[200px] overflow-hidden rounded-md">
          <img loading="lazy"
            src={courseImg !== null ? courseImg : dfCourseImg}
            alt={t("Course image")}
            className="absolute top-0 left-0 w-full h-full transition-all duration-500 group-hover:scale-125 group-hover:rotate-[10deg]"
          />
          <div className={`absolute overlay-div top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] overflow-hidden w-[5px] h-[5px] rounded-[50%] opacity-0 group-hover:w-full group-hover:h-full group-hover:opacity-100 group-hover:rounded-none bg-[#00000027] flex flex-col justify-center p-2 transition-all duration-500`}>
            <Link className="absolute top-0 left-0 w-full h-full" to={`/courses/${excutionId}`}></Link>
          </div>
        </div>
        <div className="courses-one__single-content m-0">
          <div className="courses-one__single-content-overlay-img translate-y-[-6px]">
            <img loading="lazy" src={courseInstructor && courseInstructor.image_url !== null ? courseInstructor.image_url : teacherImg} alt={t("Teacher image")} className="w-[40px] h-[40px] rounded-[50%]" />
          </div>
          <h6 className="courses-one__single-content-name">{teacher}</h6>
          <div className="relative flex justify-between items-start pt-1">
            <div className="relative flex flex-col">
              <Link to={`/courses/${excutionId}`} className="courses-one__single-content-title leading-none text-slate-600">
                {t(title)}
              </Link>
            </div>
          </div>
          {
            rating
            &&
            <div className="relative my-1">
              {calcRate(rating)}
            </div>
          }
          {courseDiscount ? (
            <div className="relative flex flex-col my-2">
              <p className="relative text-sm">
                {t("Price")}: 
                <span className="font-bold">{parseInt(price) - parseInt(courseDiscount)} {site_global_currency === "EGP" ? "EGP" : site_global_currency}</span>
                <del className="text-red-500 mr-2">{price} {site_global_currency === "EGP" ? "EGP" : site_global_currency}</del>
              </p>
            </div>
          ) : (
            <p className="courses-one__single-content-price py-2 text-slate-600">
              {t("Price")}: {price} {site_global_currency === "EGP" ? "EGP" : site_global_currency}
            </p>
          )}
          {haveThisCourse && activeStatus === "active" ? (
            <div className="relative flex justify-center">
              <Button variant="contained" className="mt-2 bg-primary py-2 col-sm-12"
                onClick={() => navigate(`/lessons/${excutionId}`)}
              >
                {t("View Course")}
                <SmartDisplayIcon className="mx-2" />
              </Button>
            </div>
          ) : (
            <div className="relative flex justify-between items-center">
              <Button variant="contained" className="mx-1"
                onClick={() => navigate(`/checkout/${excutionId}`)}
              >
                {t("Subscribe")}
              </Button>
              <Button variant="outlined" className="text-slate-800 mx-1"
                onClick={() => setShowActivationCourse(true)}
              >
                {t("Activate")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <ActivationCodeModal
        show={showActivationCourse}
        onHide={() => setShowActivationCourse(false)}
        course_execution_id={excutionId}
      />
    </>
  );
}

