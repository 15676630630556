// import { Link } from "react-router-dom";
// import "../style/style.css";
// import { useContext, useEffect, useState } from "react";
// import ContextApi from "../../../store/slices/Context";
// import { useTranslation } from "react-i18next";

// function StudentCourseCard ({index , loading , exName , courseId  , courseName , startDate , endDate , courseImg , teacherName , teacherImg , courseStatus , customClasses , courseDiscount , total_price , courseExId}){
//     const {t} = useTranslation()
//     const contextApi = useContext(ContextApi);
//     const [mounted , setMounted] = useState()
//     useEffect(() => {
//         !loading ? setTimeout(()=>setMounted(true) , 500) : setMounted(false)
//     },[loading])
//     const site_global_currency = contextApi?.site_global_currency;
//     return (
//         <>
//             <div style={{"animationDelay" : `.${index}s`}} className={`${mounted ? "active" : ""} student-course-card relative shadow-md shadow-slate-200 mx-2 col-sm-12 col-md-4 col-lg-3  transition-all duration-500 ${customClasses} p-2`}>
//                 <div className={`z-[99] absolute top-[10px] left-[10px] text-sm p-2 text-white rounded-md ${courseStatus  === "active" ? "bg-[#153776]" : "bg-red-500"}`}>
//                     {
//                         courseStatus === "active"
//                         ?
//                         t("نشط")
//                         :
//                         t("انتهي")
//                     }
//                 </div>
//                 <div className="course-img relative w-full h-[200px] overflow-hidden rounded-[2px] ">
//                     <img src={courseImg !== null ? courseImg : "https://images.pexels.com/photos/5553933/pexels-photo-5553933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} alt="course img" className="absolute top-0 left-0 w-full transition-all duration-500 hover:scale-105" />
//                 </div>
//                 <div className="course-info relative flex flex-col my-2 p-2">
//                     <Link to={courseStatus === "active" ? `/lessons/${courseExId}` : "#"} className="my-2">{courseName}</Link>
//                     <p className="text-sm mb-2">{exName}</p>
//                     {
//                         courseDiscount && parseInt(courseDiscount) >= 1
//                         ?
//                         <div className="relative flex flex-col mb-1">
//                             <p className="relative text-sm">
//                                 {t("السعر")} : 
//                                 <span className="font-bold">
//                                     {parseInt(total_price) - parseInt(courseDiscount)} 
//                                     {site_global_currency}
//                                 </span>
//                                 {" "}
//                                 <del className="text-red-500 mr-2">
//                                     {total_price} {site_global_currency}
//                                 </del>
//                             </p>
//                         </div>
//                         :
//                         <p className="courses-one__single-content-price mb-1 py-2 text-slate-600">
//                         {t("السعر")} : {total_price} {site_global_currency}
//                         </p>
//                     }
//                     <div className="relative flex flex-col">
//                         <p className="relative text-sm">{("مدة الاشتراك")}</p>
//                         <div className="relative flex">
//                             <h4 className="text-sm">{t("من")} : {startDate}</h4>
//                             <h4 className="text-sm">{t("الي")} : {endDate}</h4>
//                         </div>
//                     </div>
//                     <Link className="absolute top-0 left-0 w-full h-full z-[2]"
//                         to = {courseStatus === "active" ? `/lessons/${courseExId}` : "#"}
//                     ></Link>
//                 </div>
//             </div>
        
//         </>
//     )
// }
// export default StudentCourseCard;

import { Link } from "react-router-dom";
import "../style/style.css";
import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/slices/Context";
import { useTranslation } from "react-i18next";

function StudentCourseCard({
  index,
  loading,
  exName,
  courseId,
  courseName,
  startDate,
  endDate,
  courseImg,
  teacherName,
  teacherImg,
  courseStatus,
  customClasses,
  courseDiscount,
  total_price,
  courseExId
}) {
  const { t } = useTranslation();
  const contextApi = useContext(ContextApi);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    !loading ? setTimeout(() => setMounted(true), 500) : setMounted(false);
  }, [loading]);

  const site_global_currency = contextApi?.site_global_currency;

  return (
    <>
      <div
        style={{ animationDelay: `.${index}s` }}
        className={`${mounted ? "active" : ""} student-course-card relative shadow-md shadow-slate-200 mx-2 col-sm-12 col-md-4 col-lg-3 transition-all duration-500 ${customClasses} p-2`}
      >
        <div
          className={`z-[99] absolute top-[10px] left-[10px] text-sm p-2 text-white rounded-md ${
            courseStatus === "active" ? "bg-[#153776]" : "bg-red-500"
          }`}
        >
          {courseStatus === "active" ? t("Active") : t("Expired")}
        </div>
        <div className="course-img relative w-full h-[200px] overflow-hidden rounded-[2px]">
          <img
            src={
              courseImg !== null
                ? courseImg
                : "https://images.pexels.com/photos/5553933/pexels-photo-5553933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            }
            alt="course img"
            className="absolute top-0 left-0 w-full transition-all duration-500 hover:scale-105"
          />
        </div>
        <div className="course-info relative flex flex-col my-2 p-2">
          <Link
            to={courseStatus === "active" ? `/lessons/${courseExId}` : "#"}
            className="my-2"
          >
            {courseName}
          </Link>
          <p className="text-sm mb-2">{exName}</p>
          {courseDiscount && parseInt(courseDiscount) >= 1 ? (
            <div className="relative flex flex-col mb-1">
              <p className="relative text-sm">
                {t("Price")} :{" "}
                <span className="font-bold">
                  {parseInt(total_price) - parseInt(courseDiscount)}{" "}
                  {site_global_currency}
                </span>{" "}
                <del className="text-red-500 mr-2">
                  {total_price} {site_global_currency}
                </del>
              </p>
            </div>
          ) : (
            <p className="courses-one__single-content-price mb-1 py-2 text-slate-600">
              {t("Price")} : {total_price} {site_global_currency}
            </p>
          )}
          <div className="relative flex flex-col">
            <p className="relative text-sm">{t("Subscription Duration")}</p>
            <div className="relative flex">
              <h4 className="text-sm">
                {t("From")} : {startDate}
              </h4>
              <h4 className="text-sm">
                {t("To")} : {endDate}
              </h4>
            </div>
          </div>
          <Link
            className="absolute top-0 left-0 w-full h-full z-[2]"
            to={courseStatus === "active" ? `/lessons/${courseExId}` : "#"}
          ></Link>
        </div>
      </div>
    </>
  );
}

export default StudentCourseCard;
